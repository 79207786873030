import React from "react";

// @ts-ignore
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
// @ts-ignore
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
registerCoreBlocks();

const kSubmitAPI = "https://sleepscore.babysleep.com/api/";

export const SurveyLayout: React.FunctionComponent = () => {
    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <Form
                applyLogic={true}
                formId={7400}
                formObj={{
                    blocks: [
                        {
                            id: "2encbdvxr",
                            name: "welcome-screen",
                            attributes: {
                                buttonText: "I agree & Let's Begin!",
                                nextBtnLabel: false,
                                classnames: "",
                                attachment: {
                                    type: "image",
                                    url: "https://www.babysleep.com/wp-content/uploads/2024/01/rickrollbaby.jpg",
                                },
                                description:
                                    "With data collected on over 100,000 babies, we have generated a baby sleep score with recommendations to help you and your baby get a good night\u2019s sleep.\n\n<em>          </em>",
                                label: "Get your child&#39;s SleepScore &amp; Sleep Recommendations",
                                customHTML:
                                    '<p>v.01.05.24.1722\n<p>(This assessment will take approx. 3 mins)\n<p><b> BY PARTICIPATING YOU AGREE YOU ARE A US RESIDENT AND TO OUR <p><a href="https://www.babysleep.com/csp-terms-of-use-and-privacy-policy/" target="_blank">TERMS OF USE & PRIVACY POLICY </a>',
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "vfg305nqt",
                            name: "short-text",
                            attributes: {
                                minCharacters: false,
                                setMaxCharacters: false,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                placeholder: false,

                                defaultValue: "",
                                description:
                                    "We\u2019d love to refer to your baby more personally with a nickname, but feel free to simply enter \u201cBaby\u201d if you wish.",
                                label: "What is your child&#39;s nickname?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "qzdnpbwqo",
                            name: "slider",
                            attributes: {
                                min: 0,
                                max: "36",
                                step: "1",
                                prefix: "",
                                suffix: " months",
                                marks: "no",
                                customMarks: [{ label: "", value: "" }],
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                defaultValue: "",
                                description: "",
                                label: "What is your child&#39;s age in months?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "kxalh4nv4",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Girl",
                                    },
                                    {
                                        value: "snss431kti",
                                        label: "Boy",
                                    },
                                    {
                                        value: "8glpwzzfln",
                                        label: "Other",
                                    },
                                    {
                                        value: "vf8r39hdwe",
                                        label: "Prefer not to say",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: false,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "What is your child&#39;s gender?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "deu3y6zh3",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Mother",
                                    },
                                    {
                                        value: "h9wyqq4i6o",
                                        label: "Father",
                                    },
                                    {
                                        value: "0dfdamqk7w",
                                        label: "Grandparent",
                                    },
                                    {
                                        value: "62u8h1cx7z",
                                        label: "Other Family Member",
                                    },
                                    {
                                        value: "djedvm0jdu",
                                        label: "Health Care Provider",
                                    },
                                    {
                                        value: "uphwx4tujr",
                                        label: "Other",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: false,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "What is your relationship to your child?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "11og1u27u",
                            name: "number",
                            attributes: {
                                setMax: true,
                                max: "99999",
                                setMin: true,
                                min: "1",
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                placeholder: "eg. 92014",

                                defaultValue: "",
                                description:
                                    "We use zip code to help us understand localization, weather, access to health services and population information such as density.",
                                label: "What is your home zip code?",
                                customHTML:
                                    "<p> <b>NOTE: for zips that begin with 0 please only enter the numbers after the 0</p><p>NO US ZIP? If you have no US zip code, enter 99999</b></p>",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "95qmgonsu",
                            name: "statement",
                            attributes: {
                                buttonText: "Continue",
                                quotationMarks: true,
                                required: false,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "Please think about your child\u2019s sleep <strong>during the past two weeks</strong> when answering the following questions.",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "hsv8nwexw",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Own room",
                                    },
                                    {
                                        value: "j439e6hzu4",
                                        label: "Parents' room",
                                    },
                                    {
                                        value: "ch09t0xlk2",
                                        label: "Siblings' or other's room",
                                    },
                                    {
                                        value: "88vcimlw06",
                                        label: "Other room of the house",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Where does your child sleep most of the time?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "19mv0dccy",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Crib / Bassinet",
                                    },
                                    {
                                        value: "updbvtlnm4",
                                        label: "Own bed (any size)",
                                    },
                                    {
                                        value: "4gy49bl3k6",
                                        label: "Parents' bed",
                                    },
                                    {
                                        value: "v32tuooyxg",
                                        label: "Seat / Swing",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "What does your child sleep in most of the time?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "z02ri4hli",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "kjy9ts0byj",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Does your child usually fall asleep while being fed (nursing or bottle)?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "h0j606awz",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "While being rocked or held",
                                    },
                                    {
                                        value: "g3d70cxcd1",
                                        label: "In a swing or stroller",
                                    },
                                    {
                                        value: "p8nknjt1nd",
                                        label: "Watching TV, tablet or phone",
                                    },
                                    {
                                        value: "e1mdpa5gnn",
                                        label: "On their own",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How does your child fall asleep most of the time?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "kgyf9c398",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "In own bed/crib",
                                    },
                                    {
                                        value: "z68cnredsj",
                                        label: "In parents' bed",
                                    },
                                    {
                                        value: "1yslnd4d0n",
                                        label: "Other",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Where does your child fall asleep?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "98h928c74",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Never",
                                    },
                                    {
                                        value: "7lm2itl4h2",
                                        label: "1-2 nights per week",
                                    },
                                    {
                                        value: "bm10i0tkeo",
                                        label: "3-4 nights per week",
                                    },
                                    {
                                        value: "8d5uhe53eg",
                                        label: "5-6 nights per week",
                                    },
                                    {
                                        value: "wdi7wlcnt",
                                        label: "Every night",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How often does your child have the same bedtime routine?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "3xqgbb2rm",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "What time do you start your child\u2019s bedtime routine?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "xgnpst6iz",
                                    name: "slider",
                                    attributes: {
                                        min: "5",
                                        max: "12",
                                        step: "1",
                                        prefix: "",
                                        suffix: "",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: true,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "",
                                        description: "",
                                        label: "At what hour? (in the evening)",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "qzx72gde4",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: ": ",
                                        suffix: "",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "what minute during that hour?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "0ajg1aity",
                            name: "slider",
                            attributes: {
                                min: 0,
                                max: "150",
                                step: "5",
                                prefix: "",
                                suffix: " mins",
                                marks: "no",
                                customMarks: [{ label: "", value: "" }],
                                required: false,
                                nextBtnLabel: false,
                                classnames: "",
                                defaultValue: "00",
                                description: "",
                                label: "How long is your child&#39;s bedtime routine?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "ekllrk22m",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Very easy",
                                    },
                                    {
                                        value: "m0y8t2bzo0",
                                        label: "Somewhat easy",
                                    },
                                    {
                                        value: "hvim9yjvfm",
                                        label: "Neither easy nor difficult",
                                    },
                                    {
                                        value: "dpm89weunr",
                                        label: "Somewhat difficult",
                                    },
                                    {
                                        value: "vvdrxmt75z",
                                        label: "Very difficult",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Typically, how difficult is bedtime?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "18urn2gol",
                            name: "slider",
                            attributes: {
                                min: 0,
                                max: "150",
                                step: "5",
                                prefix: "",
                                suffix: " mins",
                                marks: "no",
                                customMarks: [{ label: "", value: "" }],
                                required: false,
                                nextBtnLabel: false,
                                classnames: "",
                                defaultValue: "00",
                                description: "",
                                label: "How long does it typically take your child to fall asleep at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "iq2m4p39o",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "At least 3 nights a week",
                                    },
                                    {
                                        value: "6msa5bqjyh",
                                        label: "Fewer than 3 nights a week",
                                    },
                                    {
                                        value: "rhgzla4lpa",
                                        label: "Never",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How often does your child have a difficult time falling asleep at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "crnnk38k0",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "What time do you usually put your child to bed at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "6j6pb5mux",
                                    name: "slider",
                                    attributes: {
                                        min: "5",
                                        max: "12",
                                        step: "1",
                                        prefix: "",
                                        suffix: "",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: true,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "",
                                        description: "",
                                        label: "At what hour? (in the evening)",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "lf48y95b9",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: ": ",
                                        suffix: "",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "",
                                        description: "",
                                        label: "what minute during that hour?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "9bold3e94",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "Total time your child sleeps at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "lamnouui3",
                                    name: "slider",
                                    attributes: {
                                        min: "0",
                                        max: "13",
                                        step: "1",
                                        prefix: "",
                                        suffix: " hours",
                                        marks: "no",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: true,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "",
                                        description: "",
                                        label: "Number of hours?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "7y8riuh4i",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: "",
                                        suffix: " mins",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "... and minutes?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "9rhpjs05i",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "Total time your child sleeps during the day?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "zla3dhnpf",
                                    name: "slider",
                                    attributes: {
                                        min: "0",
                                        max: "13",
                                        step: "1",
                                        prefix: "",
                                        suffix: " hours",
                                        marks: "no",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "Number of hours?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "zbm5bralr",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: "",
                                        suffix: " mins",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "... and minutes?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "f5h6dqfiv",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "3 nights a week or more",
                                    },
                                    {
                                        value: "1kct7805t9",
                                        label: "Fewer than 3 nights a week",
                                    },
                                    {
                                        value: "znm6mrywi2",
                                        label: "Never",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How often does your child wake during the night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "i93z6rlcj",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "0",
                                    },
                                    {
                                        value: "65i8mxffzw",
                                        label: "1",
                                    },
                                    {
                                        value: "insf9y4tlf",
                                        label: "2",
                                    },
                                    {
                                        value: "lslmxpcqz4",
                                        label: "3",
                                    },
                                    {
                                        value: "y2xgm0didf",
                                        label: "4",
                                    },
                                    {
                                        value: "5h3v9dhrnr",
                                        label: "5 or more",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: false,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Times your child typically wakes at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "dttw663wv",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually feed (nurse or bottle) them?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "izr9bbs89",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually give your child a pacifier?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "qsrcly0ys",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually change their diaper?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "eqkr0qkh0",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually watch TV, a tablet or a phone with them?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "6y26sizvl",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually comfort (patting, bringing to your bed) them back to sleep?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "n5fmhzdce",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Yes",
                                    },
                                    {
                                        value: "hrcfb8hqdl",
                                        label: "No",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "<strong>When your child wakes up at night:</strong>\n\nDo you usually pick them up and place them back down awake?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "yu2eavh49",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                label: "TOTAL TIME your child is awake on a typical night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "frd9t2kho",
                                    name: "slider",
                                    attributes: {
                                        min: "0",
                                        max: "8",
                                        step: "1",
                                        prefix: "",
                                        suffix: " hours",
                                        marks: "no",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "Number of hours?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "g8tvloxun",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: "",
                                        suffix: " mins",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "... and minutes?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "2w8wf1m1s",
                            name: "group",
                            attributes: {
                                nextBtnLabel: false,
                                classnames: "",
                                description:
                                    "eg. if it is 4 and a half hours, enter 4 hours and 30 mins.",
                                label: "LONGEST STRETCH your child is asleep without waking?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                            innerBlocks: [
                                {
                                    id: "88hemogil",
                                    name: "slider",
                                    attributes: {
                                        min: "0",
                                        max: "13",
                                        step: "1",
                                        prefix: "",
                                        suffix: " hours",
                                        marks: "no",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: true,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "",
                                        description: "",
                                        label: "Number of hours?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                                {
                                    id: "ysixyttor",
                                    name: "slider",
                                    attributes: {
                                        min: "00",
                                        max: "45",
                                        step: "15",
                                        prefix: "",
                                        suffix: " mins",
                                        marks: "yes",
                                        customMarks: [
                                            {
                                                label: "",
                                                value: "",
                                            },
                                        ],
                                        required: false,
                                        nextBtnLabel: false,
                                        classnames: "",
                                        defaultValue: "00",
                                        description: "",
                                        label: "... and minutes?",
                                        customHTML: "",
                                        layout: "stack",
                                        attachmentFocalPoint: {
                                            x: 0.5,
                                            y: 0.5,
                                        },
                                        attachmentFancyBorderRadius: false,
                                        attachmentBorderRadius: "0px",
                                        attachmentMaxWidth: "none",
                                    },
                                },
                            ],
                        },
                        {
                            id: "njc0v7tmw",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Very well",
                                    },
                                    {
                                        value: "30vvdf3s4x",
                                        label: "Well",
                                    },
                                    {
                                        value: "2mg9k35qq2",
                                        label: "Poorly",
                                    },
                                    {
                                        value: "r1nv0uy1pj",
                                        label: "Very poorly",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How well does your child usually sleep at night?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "1gsncxn8q",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Not a problem",
                                    },
                                    {
                                        value: "7dur751t33",
                                        label: "Very small problem",
                                    },
                                    {
                                        value: "gac3opublf",
                                        label: "Small problem",
                                    },
                                    {
                                        value: "9d0acrbiai",
                                        label: "Moderate problem",
                                    },
                                    {
                                        value: "6z7vbn3bho",
                                        label: "Serious problem",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Do you consider your child\u2019s sleep a problem?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "2282layo1",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Very unsure",
                                    },
                                    {
                                        value: "3tvz0dmjpk",
                                        label: "Somewhat unsure",
                                    },
                                    {
                                        value: "oipc465oyz",
                                        label: "Neutral",
                                    },
                                    {
                                        value: "h7xf724nrz",
                                        label: "Somewhat confident",
                                    },
                                    {
                                        value: "0rzvzxpsfw",
                                        label: "Very confident",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "How confident are you in managing your child\u2019s sleep?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "2ldrrifee",
                            name: "multiple-choice",
                            attributes: {
                                choices: [
                                    {
                                        value: "124e4567e89b",
                                        label: "Not at all",
                                    },
                                    {
                                        value: "jjnmuo5u7q",
                                        label: "A small amount",
                                    },
                                    {
                                        value: "517433qhcv",
                                        label: "Somewhat",
                                    },
                                    {
                                        value: "7nd0d8m27v",
                                        label: "Very much",
                                    },
                                ],
                                max: false,
                                min: false,
                                verticalAlign: true,
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                description: "",
                                randomize: false,
                                label: "Does your child&#39;s sleep negatively affect your sleep?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "vkskm24fv",
                            name: "email",
                            attributes: {
                                required: true,
                                nextBtnLabel: false,
                                classnames: "",
                                placeholder: false,

                                defaultValue: "",
                                description:
                                    "We will now calculate your child&#39;s SleepScore and create a unique link to access a sleep analysis report.\n\n<em><strong>Note:</strong></em> <em>We will never use your email for any other purpose, nor share it with anyone.</em>",
                                label: "To which email shall we send your SleepScore report?",
                                customHTML: "",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                        {
                            id: "f7iezj1nd",
                            name: "statement",
                            attributes: {
                                showButton: true,
                                buttonText: "All done!",
                                nextBtnLabel: false,
                                classnames: "",
                                attachment: {
                                    type: "image",
                                    url: "https://www.babysleep.com/wp-content/uploads/2024/01/wonderfulbaby.jpg",
                                },
                                description:
                                    "Together, we hope to improve sleep for millions of parents and babies.",
                                label: "Thank you for your participation.\n\nCheck your email for a link to your report.",
                                customHTML:
                                    "<p>\n<br> Please share with friends and family who wish to improve their baby's sleep.\n\n<br>",
                                layout: "stack",
                                attachmentFocalPoint: {
                                    x: 0.5,
                                    y: 0.5,
                                },
                                attachmentFancyBorderRadius: false,
                                attachmentBorderRadius: "0px",
                                attachmentMaxWidth: "none",
                            },
                        },
                    ],
                }}
                onSubmit={(
                    data,
                    {
                        completeForm,
                        setIsSubmitting,
                        goToBlock,
                        setSubmissionErr,
                    }
                ) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", kSubmitAPI);
                    xhr.setRequestHeader(
                        "Content-Type",
                        "application/json; charset=utf-8"
                    );
                    xhr.onload = xhr.onerror = function () {
                        if (xhr.status === 200) {
                            // Display the "Success" message (pass a string value to display a custom message)
                            setIsSubmitting(false);
                            completeForm();
                            // Alternatively, you can clear all messages:
                            // options.clearSaveMessages();
                        } else {
                            // Display the "Error" message (pass a string value to display a custom message)
                            setSubmissionErr("FAILED");
                        }
                    };
                    xhr.send(JSON.stringify(data));
                }}
            />
        </div>
    );
};
