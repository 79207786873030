import React from "react";
import { Link } from "react-router-dom";

import logo from "./../../babysleepscorelogo.png";
import phone from "./../../screenshot.png";
import bg from "./../../welcome_bg.jpg";

export const WelcomeLayout: React.FunctionComponent = () => (
    <div
        style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
        }}
    >
        <img src={logo} className="App-logo" alt="logo" />
        <div style={{ alignItems: "stretch" }}>
            <div style={{ paddingTop: "100px" }}>
                <img src={phone} className="Report-screenshot" alt="report" />
            </div>

            <div className="Welcome-text">
                <h3>How is your little one sleeping?</h3>
                <p>
                    With data collected on over 100,000 babies, we have
                    generated a baby sleep score with recommendations to help
                    you and your baby get a good night’s sleep.
                </p>
                <Link to={"/survey"}>
                    <div className="App-link">Let's Begin</div>
                </Link>
                <p className="Welcome-note">
                    This questionnaire will take around 3 minutes to complete.
                </p>
            </div>
            <div style={{ clear: "both", height: "20vh" }}></div>
        </div>
    </div>
);
