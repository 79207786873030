import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";

// Layouts
import { SurveyLayout } from "./layouts/survey";
import { WelcomeLayout } from "./layouts/welcome";
import { UnavailableLaout } from "./layouts/unavailable";

// Route Table TODO: Move to separate file
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Navigate to="/welcome" replace />, // <-- redirect
            },
            { path: "welcome", element: <WelcomeLayout /> },
            { path: "survey", element: <SurveyLayout /> },
            { path: "unavailable", element: <UnavailableLaout /> },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
