import React from "react";
import "./App.css";
import { Outlet } from "react-router-dom";

function App() {
    return (
        <div className="App">
            {/* <header className="App-header" /> */}
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <Outlet />
        </div>
    );
}

export default App;
