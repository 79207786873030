import React from "react";
import bg from "./../../welcome_bg.jpg";

export const UnavailableLaout: React.FunctionComponent = () => (
    <div
        style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
        }}
    >
        <div style={{ paddingTop: "100px" }}> </div>
        <div style={{ alignItems: "stretch" }}>
            <div className="Unavailable-text">
                <h1
                    style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "6em",
                    }}
                >
                    Unavailable
                </h1>
            </div>
        </div>
        <div style={{ clear: "both", height: "90vh" }}></div>
    </div>
);
